import { attr, hasMany } from '@ember-data/model';
import { DateTime } from 'luxon';
import {
  FALLBACK_COVER_URL,
  PRODUCT_FORMS_NOT_ORDERABLE,
  PRODUCT_TYPES,
  PUBLISHING_STATUS,
  RETAIL_SALE_TYPES,
  UNPRICED_ITEM_CODES_NOT_ORDERABLE,
} from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import isProductOnDispoList from '@mvb/tix-ui/utils/is-product-on-dispo-list';
import Model from './-base';

export default class SearchProductModel extends Model {
  @service abilities;
  @service store;

  @attr('boolean') active;
  @attr() aggregatorId;
  @attr() announcementDate;
  @attr() availability;
  @attr() availabilityDate;
  @attr() availabilityStatus;
  @attr() availabilityStateFeed;
  @attr() changes;
  @attr() containedItems;
  @attr('string', FALLBACK_COVER_URL) coverUrl;
  @attr() creationDate;
  @attr() defaultCategoryOfGoodsCode;
  @attr() defaultMediaIndex;
  @attr('number') digitalPagesArabic;
  @attr() drmCode;
  @attr('number') duration;
  @attr() fileFormat;
  @attr('number') fileSize;
  @attr('string') genreCode;
  @attr() gtin;
  @attr('string') highlighting;
  @attr() highlightingBookGroups;
  @attr() identifier;
  @attr('string') interestAgeFrom;
  @attr('string') interestAgeFromMonths;
  @attr() isbn;
  @attr('number') numDiscs;
  @attr('boolean') onList;
  @attr() onSaleDate;
  @attr('number') pages;
  @attr('number') pagesArabic;
  @attr() pieces;
  @attr() primaryContentType;
  @attr() productFlags;
  @attr() productFormDetail;
  @attr() productFormId;
  @attr() productFormWithDetail;
  @attr() productType;
  @attr() proprietaryIds;
  @attr() publicationDate;
  @attr() publisher;
  @attr() publisherMvbId;
  @attr('string') publishingStatus;
  @attr('string') readingAgeFrom;
  @attr('string') retailSale;
  @attr() subTitle;
  @attr() title;
  @attr() unpricedItemCode;

  @attr('string') readingRationaleSubject;
  @attr('string') readingRationaleMainSubject;

  @attr() aggregatedPrices;
  @attr() collections;
  @attr() contributors;
  @attr('string') contributorsRepresentationLong;
  @attr('string') contributorsRepresentationShort;

  @hasMany('note', { async: false, inverse: null }) notes;

  get batchIds() {
    if (this.proprietaryIds?.length > 0) {
      return [this.gtin, ...this.proprietaryIds, this.id].filter(Boolean);
    }
    return [this.gtin, this.id].filter(Boolean);
  }

  get ean() {
    return this.gtin || this.isbn;
  }

  // this check is needed for displaying a lock icon e.g. in the list-view or in components using the card
  get isInDraftState() {
    let isDraft = false;

    if (this.announcementDate) {
      isDraft = DateTime.fromFormat(this.announcementDate, 'dd.LL.yyyy') > DateTime.now();
    }

    return isDraft || (this.isTixOnly && this.publishingStatus === PUBLISHING_STATUS.INACTIVE);
  }

  get isOnDispoList() {
    let dlpeModelname = this.abilities.can('dispoList.useThaliaOrderGrid')
      ? 'thalia-dispo-list-product-entry'
      : 'dispo-list-product-entry';
    let dispoListProducts = this.store
      .peekAll('dispo-list-product')
      .filter((record) => (!record.isDeleted || !record.hasDirtyAttributes) && !record.isNew);
    let dispoListProductEntries = this.store
      .peekAll(dlpeModelname)
      .filter((record) => (!record.isDeleted || !record.hasDirtyAttributes) && !record.isNew);

    return isProductOnDispoList({ dispoListProducts, dispoListProductEntries, productId: this.id });
  }

  get isOrderable() {
    return (
      !PRODUCT_FORMS_NOT_ORDERABLE.includes(this.productFormId) &&
      !this.isThemenspecial &&
      !UNPRICED_ITEM_CODES_NOT_ORDERABLE.includes(this.unpricedItemCode)
    );
  }

  get isProductCollection() {
    return this.isPromotionalPackage || this.isThemenspecial;
  }

  get isPromotionalPackage() {
    return this.retailSale === RETAIL_SALE_TYPES.PROMOTIONAL_PACKAGE;
  }

  get isThemenspecial() {
    return this.retailSale === RETAIL_SALE_TYPES.SPECIAL;
  }

  get isTixOnly() {
    return this.productType === PRODUCT_TYPES.TIX_ONLY;
  }

  getAggregatedPrice(currencyCountry) {
    let currency = currencyCountry.split('_')[0];
    let country = currencyCountry.split('_')[1];
    return this.aggregatedPrices?.find((price) => price.currency === currency && price.country === country);
  }
}
