import { action } from '@ember/object';
import { all, task } from 'ember-concurrency';
import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';
import {
  MAX_PRODUCTS_FOR_BATCH_ADD,
  MAX_PRODUCTS_FOR_PRESENTATION,
  TIX_ITEM_SOURCE_TYPE,
  URL_DISPOLISTS_IMPORT_THALIA_QUANTITIES,
} from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import DispoListsModalSendDispoListIndexComponent from '@mvb/tix-ui/components/dispo-lists/modal-send-dispo-list';
import ModalsModalThaliaImportIndexComponent from '@mvb/tix-ui/components/modals/modal-thalia-import';
import queryParamsPack from '@mvb/tix-ui/utils/query-params-pack';

export default class OrderDispoListsDispoListIndexController extends Controller {
  @service api;
  @service dispoLists;
  @service errors;
  @service eventBus;
  @service intl;
  @service manageList;
  @service modals;
  @service notifications;
  @service progress;
  @service router;
  @service('search-products-in-dispo-list') searchService;
  @service store;
  @service user;
  @service userState;
  @service('query-params') queryParamsService;

  BUTTON_TYPES = BUTTON_TYPES;
  ICON_TYPES = ICON_TYPES;
  MAX_PRODUCTS_FOR_BATCH_ADD = MAX_PRODUCTS_FOR_BATCH_ADD;
  MAX_PRODUCTS_FOR_PRESENTATION = MAX_PRODUCTS_FOR_PRESENTATION;

  queryParams = ['qp'];
  @tracked currentProgress;
  @tracked isLoading = false;
  @tracked qp = '';

  constructor() {
    super(...arguments);
    this.eventBus.on('setDispoListIsLoading', this, this.setIsLoading);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.eventBus.off('setDispoListIsLoading', this, this.setIsLoading);
  }

  get addToOrderListLabel() {
    return this.maxProductCountExceeded
      ? this.intl.t('uiFunctionBar.tooltip.orderListMaxProductCountExceeded')
      : this.intl.t('uiFunctionBar.tooltip.moveToShoppingCart');
  }

  get isItemsSelectionEmpty() {
    return this.manageList.isSelectionEmpty || this.searchService.meta.total === 0;
  }

  get maxProductCountExceeded() {
    let selectedProductCount = this.manageList.determineSelectedElementsCount(this.searchService.meta.total);
    return selectedProductCount > this.MAX_PRODUCTS_FOR_BATCH_ADD;
  }

  get shouldShowNoResultsAction() {
    return !this.model.dispoList.defaultByAnyone && this.model.dispoList.productCount < 1;
  }

  @action
  async onDeleteFromDispoList() {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('modalDeleteFromDispoList.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
        name: 'no-items-selected',
      });
    }

    let affectsResults = false;

    try {
      let filters = this.searchService.searchQuery.filter || {};
      let selectedItems = this.searchService.results
        .filter((result) => this.manageList.selectedItems.has(result.dispoListProduct.id))
        .map((result) => result.searchProduct.id);

      affectsResults = await this.dispoLists.deleteFromDispoList({
        dispoList: this.model.dispoList,
        filters,
        invertedSelection: this.manageList.inverse,
        selectedItems,
      });
    } catch (error) {
      this.errors.handle(error);
    }

    if (affectsResults) {
      await this.updateResults(this.intl.t('modalDeleteFromDispoList.notification.success'));
    }
  }

  @action
  async onMoveSelectedToOrderList() {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('modalMoveToOrderList.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
        name: 'no-items-selected',
      });
    }

    let affectsResults = false;

    try {
      let filters = this.searchService.searchQuery.filter || {};

      delete filters['dispoList.id'];
      delete filters['onList'];

      affectsResults = await this.dispoLists.moveToOrderList({
        dispoList: this.model.dispoList,
        filters,
        invertedSelection: this.manageList.inverse,
        selectedItems: Array.from(this.manageList.selectedItems),
        title: this.intl.t('modalMoveToOrderList.text.title'),
      });
    } catch (error) {
      this.errors.handle(error);
    }

    if (affectsResults) {
      await this.updateResults(this.intl.t('modalMoveToOrderList.notification.success'));
    }
  }

  @action
  async onSendDispoList() {
    if (this.isItemsSelectionEmpty) {
      return this.modals.notify({
        message: this.intl.t('modalSendDispoList.notification.noItemsSelected'),
        close: this.intl.t('modalNotify.action.confirm'),
        name: 'no-items-selected',
      });
    }

    let result = await this.modals.open(DispoListsModalSendDispoListIndexComponent, {
      title: this.intl.t('modalSendDispoList.text.title'),
    });

    if (!result) {
      return false;
    }

    let progress = this.progress.add({
      message: this.intl.t('modalSendDispoList.text.progress'),
    });

    let filters = this.searchService.searchQuery.filter || {};
    let isbns = this.store
      .peekAll('dispo-list-product')
      .filter((dlp) => this.manageList.selectedItems.has(dlp.id))
      .map((dlp) => dlp.productIsbn);

    delete filters['dispoList.id'];
    delete filters['onList'];

    let data = {
      dispoList: this.model.dispoList,
      filters,
      invertedSelection: this.manageList.inverse,
      selectedItems: isbns,
    };

    try {
      await this.sendDispoListTask.perform({ ...data, ...result });

      progress.remove();
      this.notifications.success(this.intl.t('modalSendDispoList.notification.success'));
    } catch (error) {
      progress.remove();
      this.errors.handle(error);
    }

    return true;
  }

  @action
  showPresentation(openInNewTab = false) {
    let selectedElementsCount = this.manageList.determineSelectedElementsCount(this.searchService.meta.total);
    // if we have nothing selected, we assume we want to open the presentation mode with all products
    // this should have the same behaviour as the preview presentation mode
    if (selectedElementsCount === 0) {
      selectedElementsCount = this.searchService.meta.total;
    }

    // if we still have no elements, we show the notification so the user does not get confused why the presentation mode redirects back to the dispolist
    if (selectedElementsCount === 0) {
      this.modals.notify({
        message: this.intl.t('dispoList.notification.noSelection'),
        close: this.intl.t('modalNotify.action.confirm'),
      });
      return;
    }

    if (selectedElementsCount > this.MAX_PRODUCTS_FOR_PRESENTATION) {
      this.modals.notify({
        message: this.intl.t('dispoList.notification.tooManySelected', {
          selected: this.intl.formatNumber(selectedElementsCount),
        }),
        close: this.intl.t('modalNotify.action.confirm'),
      });
      return;
    }

    let url = this.router.urlFor('presentations.dispo-list', this.model.dispoList.id);
    let queryParams = {
      ...this.queryParamsService.currentQueryParams,
      selectedDispoListProductIds: Array.from(this.manageList.selectedItems).join(','),
      inverse: this.manageList.inverse,
    };
    queryParams = queryParamsPack(queryParams);

    if (openInNewTab) {
      window.open(`${url}?qp=${queryParams.qp}`, '_blank').focus();
      return;
    }

    this.router.transitionTo('presentations.dispo-list', this.model.dispoList.id, { queryParams });
  }

  @action
  onImportThaliaProducts() {
    return this.modals.open(ModalsModalThaliaImportIndexComponent, {
      translationPath: 'dispoListsList.modal.thaliaImport',
      endpoint: URL_DISPOLISTS_IMPORT_THALIA_QUANTITIES,
      dispoListId: this.model.dispoList.id,
    });
  }

  @action
  onSaveEntries(dispoListProduct) {
    this.saveEntriesTask.unlinked().perform(dispoListProduct);
  }

  @task
  @waitFor
  *destroyRecordAndReloadListTask(record, index) {
    try {
      yield record.destroyRecord();
      yield this.reloadListTask.perform({ fullReload: true }, index);
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *reloadListTask({ fullReload = false } = {}, index) {
    try {
      yield all([this.model.dispoList?.reload(), this.userState.load()]);

      if (fullReload) {
        return index ? this.searchService.search({ index }) : this.router.refreshCurrentRoute({ withoutIndex: true });
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *saveEntriesTask(dispoListProduct) {
    try {
      let productIsToBeDeleted = yield dispoListProduct?.get('dispoListProductEntries')?.length === 0;
      yield this.reloadListTask.perform({ fullReload: productIsToBeDeleted });
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *sendDispoListTask(data) {
    try {
      yield this.api.postJSON('/preorder', {
        body: data.body,
        identifiers: data.selectedItems,
        invertedSelection: data.invertedSelection,
        filters: data.filters ?? {},
        recipients: data.recipients,
        sort: this.searchService.searchQuery.sort,
        sourceId: data.dispoList.id,
        sourceType: TIX_ITEM_SOURCE_TYPE.DISPO_LIST,
        subject: data.subject,
      });
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @action
  setIsLoading(value) {
    this.isLoading = value;
    this.handleProgress();
  }

  handleProgress() {
    if (this.isLoading) {
      this.currentProgress = this.progress.add({
        message: this.intl.t('loading.progress'),
      });
    }

    if (!this.isLoading && this.currentProgress) {
      this.currentProgress.remove();
    }
  }

  async updateResults(message) {
    this.manageList.reset();
    await all([this.model.dispoList.reload(), this.userState.load()]);

    this.notifications.success(message);

    return this.router.refreshCurrentRoute();
  }
}
