import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';

export default class ProductListsProductListIndexController extends Controller {
  @service eventBus;
  @service intl;
  @service productList;
  @service('products') productsService;
  @service progress;
  @service('search-products-in-list') searchService;
  @service user;

  queryParams = ['qp'];
  @tracked currentProgress;
  @tracked isLoading = false;
  @tracked qp = '';

  BUTTON_TYPES = BUTTON_TYPES;
  ICON_TYPES = ICON_TYPES;

  constructor() {
    super(...arguments);
    this.eventBus.on('setProductListIsLoading', this, this.setIsLoading);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.eventBus.off('setProductListIsLoading', this, this.setIsLoading);
  }

  get addToDispoListLabel() {
    return this.productsService.getAddToDispoListLabel(this.maxProductCountExceeded);
  }

  get addToProductListLabel() {
    return this.productsService.getAddToProductListLabel(this.maxProductCountExceeded);
  }

  get isItemsSelectionEmpty() {
    return this.productsService.getIsItemsSelectionEmpty(this.searchService.meta.total);
  }

  get maxProductCountExceeded() {
    return this.productsService.getMaxProductCountExceeded(this.searchService.meta.total);
  }

  get updatedFilter() {
    let filter = this.searchService.searchQuery.filter;
    filter['productList.id'] = this.model.list.id;
    return filter;
  }

  @action
  async onAddToDispoList() {
    let affectsResults = await this.productsService.onAddToDispoList(this.searchService.meta.total, this.updatedFilter);

    if (affectsResults) {
      this.reloadResults();
    }
  }

  @action
  onAddToProductList() {
    this.productList.addProductsToList(this.searchService.meta.total, this.updatedFilter);
  }

  @action
  onDeleteFromList() {
    this.productList.deleteProductsFromList(this.searchService.meta.total, this.updatedFilter, this.model.list.id);
  }

  @action
  onDownload() {
    this.productsService.onDownload();
  }

  @action
  onStartPresentation() {
    this.productsService.onStartPresentation(this.model.list.id, true);
  }

  @action
  reloadResults(index) {
    this.searchService.search({ index });
  }

  @action
  async onUpdateProductLists(product, preview, index) {
    let { itemOnList } = await this.productList.updateListsForProduct(product, preview);

    if (!itemOnList.get(this.model.list.id)) {
      this.reloadResults(index);
    }
  }

  @action
  setIsLoading(value) {
    this.isLoading = value;
    this.handleProgress();
  }

  handleProgress() {
    if (this.isLoading) {
      this.currentProgress = this.progress.add({
        message: this.intl.t('loading.progress'),
      });
    }

    if (!this.isLoading && this.currentProgress) {
      this.currentProgress.remove();
    }
  }
}
