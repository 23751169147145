import { bind } from '@ember/runloop';
import { compare, isPresent } from '@ember/utils';
import { SEARCH_MODE, SECTION_TYPES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchProductsInPreviewService extends SearchBaseService {
  @service abilities;
  @service('dispo-list-product-entries') dispoListProductEntriesService;
  @service intl;
  @service store;
  @service user;

  get queryParamsDefaults() {
    return {
      ...this.queryParamsDefaultsForProducts,
      ...this.queryParamsDefaultsForAdditionalInformation,
      // to get the correct order of products coming from a preview
      // we sort the list by position as default
      sort: 'position',
    };
  }

  get queryParamsFilters() {
    return this.queryParamsFiltersForProducts;
  }

  get queryParamsUserSpecific() {
    return {
      size: this.user.selectedUserPagesize,
    };
  }

  createSearchQuery({ model: preview, queryParams }) {
    let { page, size, sort } = queryParams;
    let referenceTitleSection = preview?.sections?.find((section) => section.type === SECTION_TYPES.REFERENCETITLE);
    let query = {
      filter: {
        onList: true,
        'section.id': referenceTitleSection?.id,
        searchMode: SEARCH_MODE.BATCH,
      },
      include: [
        'file',
        'customMarketingContent',
        'customMarketingContent.hugListingRecommendations',
        'customMarketingContent.hugMarketingRecommendations',
        'customMarketingContent.listingRecommendations',
        'customMarketingContent.marketingRecommendations',
        'searchProduct',
        'searchProduct.notes',
      ].join(','),
      page: {
        number: page,
        size,
      },
      sort,
    };

    this.addFiltersOfAdditionalInformationToSearchQuery({ query, queryParams });
    this.addFiltersToSearchQuery({ query, queryParams });

    return query;
  }

  async executeSearchQuery(query) {
    if (isPresent(query.filter['section.id'])) {
      if (compare(query?.filter?.publisherId?.split(','), this.user.favoritePublishers) === 0) {
        query.filter.favoritePublishers = true;
        delete query.filter.publisherId;
      }
      return await this.store.query('section-content', query);
    }

    return [];
  }

  async mapResults({ results: sectionContents }) {
    let { abilities, dispoListProductEntriesService } = this;
    let dlpes = [];
    let mappedResults = [];
    let updateAdditionalInformationFilters = bind(this, this.updateAdditionalInformationFilters);

    for (let content of sectionContents ?? []) {
      if (content.searchProduct) {
        mappedResults.push({
          position: content.position + 1,
          searchProduct: content.searchProduct,
          sectionContent: content,
        });
      }
    }

    let isbns = mappedResults.map((mappedResult) => mappedResult.searchProduct.gtin);
    isbns = [...new Set(isbns.filter(Boolean))];

    if (abilities.can('dispoList.viewDispoListsInProductPages')) {
      dlpes = await dispoListProductEntriesService.fetchByIsbns(isbns);
      dispoListProductEntriesService.cleanup();
    }

    return mappedResults.map((mappedResult) => {
      mappedResult = new (class {
        @tracked dispoListProductEntries;
        onSaveAdditionalInformationTask = task(async ({ cmcModel } = {}) => {
          if (cmcModel?.hugListingRecommendations?.length > 0 && cmcModel?.preview?.content?.isHugendubel) {
            let { refreshDispoListProductEntries } = this;
            await dispoListProductEntriesService.createForDefaultDispoListTask.perform({
              collectionOfGoods: cmcModel.assortment?.content ?? null,
              hugListingRecommendationCodes: cmcModel.hugListingRecommendations?.map((listing) => listing.code) ?? [],
              onChange: refreshDispoListProductEntries,
              preview: cmcModel.preview.content,
              productId: cmcModel.referencedProductId,
              productIsbn: cmcModel.referencedProductIsbn,
            });
          }
          updateAdditionalInformationFilters();
        });
        position = mappedResult.position;
        searchProduct = mappedResult.searchProduct;
        sectionContent = mappedResult.sectionContent;
      })();

      mappedResult.dispoListProductEntries = dispoListProductEntriesService.mapSearchProduct({
        dlpes,
        searchProduct: mappedResult.searchProduct,
      }).dispoListProductEntries;

      mappedResult.refreshDispoListProductEntries = bind(mappedResult, async function () {
        if (abilities.can('dispoList.viewDispoListsInProductPages') && mappedResult.searchProduct?.gtin) {
          let dlpesForSearchProduct = await dispoListProductEntriesService.fetchByIsbns(
            mappedResult.searchProduct.gtin
          );
          this.dispoListProductEntries = dispoListProductEntriesService.mapSearchProduct({
            dlpes: dlpesForSearchProduct,
            searchProduct: mappedResult.searchProduct,
          }).dispoListProductEntries;
        }
      });

      return mappedResult;
    });
  }

  overrideQueryParameters(queryParamsOverride) {
    return this.overrideQueryParametersForProducts(queryParamsOverride);
  }

  overrideQueryParametersOfFacetsAndAdditionalInformationFilters(queryParamsOverride) {
    return this.overrideQueryParametersOfFacetsAndAdditionalInformationFiltersForProducts(queryParamsOverride);
  }
}
