import { action } from '@ember/object';
import { BUTTON_TYPES, ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { URL_IMPORT_THALIA_REFERENCE_PRODUCTS } from '@mvb/tix-ui/constants';
import Controller from '@ember/controller';
import ModalThaliaImport from '@mvb/tix-ui/components/modals/modal-thalia-import';

export default class PreviewListsPreviewListIndexController extends Controller {
  @service errors;
  @service eventBus;
  @service intl;
  @service modals;
  @service('previews') previewsService;
  @service previewList;
  @service progress;
  @service router;
  @service('search-previews-in-list') searchService;
  @service store;

  queryParams = ['qp'];
  @tracked currentProgress;
  @tracked isLoading = false;
  @tracked qp = '';

  BUTTON_TYPES = BUTTON_TYPES;
  ICON_TYPES = ICON_TYPES;

  constructor() {
    super(...arguments);
    this.eventBus.on('setPreviewListIsLoading', this, this.setIsLoading);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.eventBus.off('setPreviewListIsLoading', this, this.setIsLoading);
  }

  @action
  onAddToList() {
    this.previewList.addPreviewsToList(
      this.searchService.meta.total,
      this.searchService.searchQuery?.filter,
      this.searchService.searchQuery?.sort
    );
  }

  @action
  onDeleteFromList() {
    this.previewList.deletePreviewsFromList(
      this.searchService.meta.total,
      this.searchService.searchQuery?.filter,
      this.searchService.searchQuery?.sort,
      this.model.list.id
    );
  }

  @action
  onSendPreview() {
    this.previewsService.onSendPreview(this.searchService.meta.total, this.searchService.searchQuery?.filter);
  }

  @action
  onImportReferenceProducts() {
    return this.modals.open(ModalThaliaImport, {
      translationPath: 'previewListsPreviewList.modal.referenceProductsImport',
      endpoint: URL_IMPORT_THALIA_REFERENCE_PRODUCTS,
      previewListId: this.model.list.id,
    });
  }

  @action
  async onUpdatePreviewLists(preview, index) {
    let { itemOnList } = await this.previewList.updateListsForPreview(preview, this.model.list);

    if (!itemOnList.get(this.model.list.id)) {
      await this.searchService.search({ index });
    }
  }

  @action
  setIsLoading(value) {
    this.isLoading = value;
    this.handleProgress();
  }

  handleProgress() {
    if (this.isLoading) {
      this.currentProgress = this.progress.add({
        message: this.intl.t('loading.progress'),
      });
    }

    if (!this.isLoading && this.currentProgress) {
      this.currentProgress.remove();
    }
  }
}
