import {
  ASSET_MODE_CODES,
  ASSET_TYPE_CODES,
  CLASSIFICATION_TYPES,
  CURRENCIES_DACH,
  FALLBACK_COVER_URL,
  FORM_FEATURE_TYPES,
  IDENTIFIER_TYPES,
  LANGUAGE_TYPES,
  PRODUCT_COMPOSITION_TYPES,
  PRODUCT_FORM_TYPES,
  PRODUCT_FORMS_NOT_ORDERABLE,
  PRODUCT_TYPES,
  PUBLISHER_TYPES,
  PUBLISHING_STATUS,
  SECTION_CONTENT_TYPES,
  SECTION_STATUS,
  SECTION_TYPES,
  STATE_MACHINE_VALUES,
  TEXT_CONTENT_AUDIENCES,
  TEXT_CONTENT_TYPE_CODES,
  TEXT_FORMATS,
  TITLE_TYPES,
  UNPRICED_ITEM_CODES_NOT_ORDERABLE,
} from '@mvb/tix-ui/constants';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { DateTime } from 'luxon';
import { htmlSafe } from '@ember/template';
import { isEmpty, isPresent } from '@ember/utils';
import { REFERENCE_PRODUCT_REL_CODES } from '@mvb/tix-ui/components/product/reference-products';
import { service } from '@ember/service';
import { sortByCodelist } from '@mvb/tix-ui/utils/internal-codelist-services';
import find from 'lodash-es/find';
import groupBy from 'lodash-es/groupBy';
import isProductOnDispoList from '@mvb/tix-ui/utils/is-product-on-dispo-list';
import Model from './-base';
import sanitizeString from '@mvb/tix-ui/utils/sanitize-string';
import sortBy from 'lodash-es/sortBy';

export default class ProductModel extends Model {
  @service abilities;
  @service codelists;
  @service store;

  // attributes
  @attr('boolean') active;
  @attr('date') announcementDate;
  @attr('string') availabilityCode;
  @attr('string') availabilityDate;
  @attr('string') availabilityStatus;
  @attr('string') countryOfManufacture;
  @attr('string') gtin;
  @attr('string') interestAgeFrom;
  @attr('string') interestAgeFromMonths;
  @attr('string') isbn;
  @attr('string') formattedIsbn;
  @attr('date') onSaleDate;
  @attr('number') orderQuantityMinimum;
  @attr('number') orderQuantityMultiple;
  @attr('string') productComposition;
  @attr('string') publicationCity;
  @attr('string') publicationCountry;
  @attr('string') publicationDate;
  @attr('string') publishingStatus;
  @attr('string') readingAgeFrom;
  @attr('string') thesisPresentedTo;
  @attr('string') thesisType;
  @attr('string') thesisYear;
  @attr('string') type;
  @attr('string') unpricedItemCode;
  @attr('string') yearFirstPublished;

  // attributes with defaults
  @attr({
    defaultValue() {
      return {
        type: null,
        text: null,
        number: null,
      };
    },
  })
  edition;
  @attr({
    defaultValue() {
      return {
        duration: null,
        fileSize: null,
        mapScale: null,
        numIllustrations: null,
        numTracks: null,
        numDiscs: null,
        illustrationText: null,
        pages: null,
        pagesRoman: null,
        pagesArabic: null,
        backMatterPagesRoman: null,
        totalPagesArabic: null,
        productionPagesArabic: null,
        absolutePagesArabic: null,
        printPagesArabic: null,
        digitalPagesArabic: null,
        totalUnnumberedArabic: null,
        fileFormat: null,
        epubTypeDescription: null,
        drm: null,
        drmCode: null,
        epubTypeVersion: null,
        epubFormatVersionCode: null,
      };
    },
  })
  extent;
  @attr({
    defaultValue() {
      return {
        typeO3: null,
        detailO3: null,
        description: null,
        productContentType: null,
        primaryContentType: null,
        height: null,
        width: null,
        weight: null,
      };
    },
  })
  form;
  @attr('boolean') onList;

  // attributes as list
  @attr() audiences;
  @attr() classifications;
  @attr() collections;
  @attr() commercialClassifications;
  @attr() formFeatures;
  @attr() highlightingBookGroups;
  @attr() identifiers;
  @attr() illustrations;
  @attr() languages;
  @attr() prices;
  @attr() productFlags;
  @attr() productParts;
  @attr() publishers; // if idType === '05', then idValue === mvbId, see constants.js PUBLISHER_ID_TYPE
  @attr() publishersSalesExpectation;
  @attr() relatedProducts;
  @attr() salesRights;
  @attr() titles;

  // relationships
  @belongsTo('custom-marketing-content', { async: true, inverse: null }) customMarketingContent;
  @hasMany('bestseller', { async: false, inverse: 'product' }) bestsellers;
  @hasMany('category-of-goods', { async: false, inverse: null }) categoriesOfGoods;
  @hasMany('contributor', { async: false, inverse: 'product' }) contributors;
  @hasMany('note', { async: false, inverse: 'product' }) notes;
  @hasMany('section', { async: false, inverse: 'product' }) sections;
  @hasMany('supporting-resource', { async: false, inverse: 'product' }) supportingResources;
  @hasMany('text-content', { async: false, inverse: 'product' }) textContents;

  // getters
  get advertisingMediaIdentifier() {
    return this.isbn ?? this.gtin;
  }

  get availability() {
    return this.availabilityCode;
  }

  get categoryOfGoodsVlb() {
    let cogs = this.classifications?.filter((cl) => cl.type === CLASSIFICATION_TYPES.COG) ?? [];
    // don't show the index and the corresponding text --> therefore the slicing and splitting
    return cogs?.map((cl) => {
      let code = cl.code.slice(1);
      let value = cl.value.split(' / ')[1];
      return { code, value };
    });
  }

  get contributorAssets() {
    return this.supportingResources?.filter(
      (asset) =>
        asset.resourceMode === ASSET_MODE_CODES.IMAGE &&
        asset.resourceContentType === ASSET_TYPE_CODES.AUTHOR &&
        !isEmpty(asset.contributorPropId)
    );
  }

  get containedPriceVisibility() {
    let currencies = Object.values(CURRENCIES_DACH);
    let result = {};
    let containedPricesSection = this.sortedSections?.find(
      (section) => section.type === SECTION_TYPES.CONTAINED_PRICES
    );
    let sectionContainedPrices = containedPricesSection?.contents[0]?.prices ?? [];

    for (let currency of currencies) {
      result[currency] = sectionContainedPrices.find(
        (containedPrice) => `${containedPrice.currency}_${containedPrice.country}` === currency
      )?.visible;
    }
    return result;
  }

  // all the products that belong to the CONTAINED_TITLES sections
  get containedProducts() {
    let section = find(this.sortedSections, { type: SECTION_TYPES.CONTAINED_TITLES });
    return section?.sortedContents ?? [];
  }

  // all the products that belong to the CONTAINED_TITLES sections, are visible and have a count
  get containedProductsWithAmountAndVisible() {
    let products = this.containedProducts;
    return products?.filter((product) => isPresent(product.count) && !product.isDeleted);
  }

  get cover() {
    // return the cover saved in the product info section (draft state included)
    let infoContents = this.info?.contents ?? [];
    if (infoContents.length > 0) {
      let coverContent = infoContents[0];
      let coverFile = coverContent.file;

      if (isPresent(coverFile)) {
        return {
          resourceContentType: ASSET_TYPE_CODES.COVER,
          exportedLink: coverFile.downloadUrl,
          assetFileId: coverFile.id,
          imageHeight: coverFile.imageHeight,
          imageWidth: coverFile.imageWidth,
          caption: null,
        };
      }
    }

    // return the cover provided by the supporting resources (only when published)
    let coverAssets =
      this.supportingResources?.filter((resource) => resource.resourceContentType === ASSET_TYPE_CODES.COVER) ?? [];
    if (coverAssets.length > 0) {
      return {
        resourceContentType: ASSET_TYPE_CODES.COVER,
        exportedLink: coverAssets[0].exportedLink,
        assetFileId: coverAssets[0].assetFileId,
        imageHeight: coverAssets[0].imageHeight,
        imageWidth: coverAssets[0].imageWidth,
        caption: coverAssets[0].caption,
      };
    }

    // return the fallback if no cover is present
    return {
      resourceContentType: ASSET_TYPE_CODES.COVER,
      exportedLink: FALLBACK_COVER_URL,
      assetFileId: 'no-cover-available',
      imageHeight: 280,
      imageWidth: 200,
    };
  }

  get deliveryInfo() {
    return this.sortedSections.find((section) => section.type === SECTION_TYPES.DATE)?.deliveryInfo;
  }

  get description() {
    let text, isHTML;

    if (this.isProductCollection) {
      text = this.productInfo?.description ?? '';
      isHTML = /<\/?(a|strong|p|ul|br)[^>]*>/g.test(text); // check for common tags
    }

    // in case we are not in a product collection or the description from the productInfo is empty, we check in the text contents for a description
    if (isEmpty(text)) {
      let descriptionTextContent = this.textContents?.find(
        (textContent) => textContent.textType === TEXT_CONTENT_TYPE_CODES.MAIN
      );
      text = descriptionTextContent?.text ?? '';
      isHTML = descriptionTextContent?.textFormat === TEXT_FORMATS.XHTML;
    }

    // TODO: check if this is still needed when #40242 was implemented
    // check if the text contains only "null" or "undefined" and replace it with an empty string
    if (text === 'null' || text === 'undefined') {
      text = '';
    }

    // Sanitize text
    text = text.trim().replaceAll(/^\s*(<br\s*\/?\s*>)+|(<br\s*\/?\s*>)+\s*$/gi, '');
    text = isHTML ? htmlSafe(text) : text;

    return text
      ? {
          text,
          isHTML,
        }
      : undefined;
  }

  get ean() {
    return this.gtin || this.isbn;
  }

  get galleryFiles() {
    let files = [];

    for (let section of this.sortedSections) {
      if (section.type === SECTION_TYPES.GALLERY) {
        for (let sectionContent of section.sortedContents) {
          let { contentType, resourceContentType, resourceMode } = sectionContent;
          let file = sectionContent.file;

          if (
            contentType === SECTION_CONTENT_TYPES.TIX &&
            resourceContentType === ASSET_TYPE_CODES.MISC &&
            resourceMode === ASSET_MODE_CODES.IMAGE &&
            isPresent(file)
          ) {
            files.push(file);
          }
        }
      }
    }

    return files;
  }

  get genreCode() {
    let cogs = this.classifications?.filter((cl) => cl.type === CLASSIFICATION_TYPES.COG) ?? [];
    return cogs.length > 0 ? cogs[0].code : null;
  }

  get groupedAndSortedContributors() {
    let sorted = sortBy(this.contributors ?? [], 'sequenceWithinRole');
    let list = groupBy(sorted, 'type');
    let codelist = this.codelists.getCodeListWithDeprecated('contributorRoleCodes');

    return sortByCodelist(list, codelist);
  }

  get groupedAndSortedReferenceProducts() {
    if (isEmpty(this.referenceProducts)) {
      return [];
    }

    let sorted = sortBy(this.referenceProducts, 'sequence');
    let list = groupBy(sorted, 'productRelationCode');
    let codelist = this.codelists.getCodeListWithDeprecated('productRelationCodesList');

    return sortByCodelist(list, codelist);
  }

  get info() {
    return this.sections?.find((section) => section.type === SECTION_TYPES.INFO);
  }

  // state machine initialState here
  get initialState() {
    if (this.isProductCollection) {
      switch (this.publishingStatus) {
        case PUBLISHING_STATUS.INACTIVE: {
          return STATE_MACHINE_VALUES.INITIAL.UNPUBLISHED;
        }
        case PUBLISHING_STATUS.ACTIVE: {
          return STATE_MACHINE_VALUES.INITIAL.PUBLISHED;
        }
        default: {
          return STATE_MACHINE_VALUES.INITIAL.UNPUBLISHED;
        }
      }
    } else {
      switch (this.status) {
        case SECTION_STATUS.DRAFT: {
          return STATE_MACHINE_VALUES.INITIAL.UNPUBLISHED;
        }
        case SECTION_STATUS.PUBLISHED: {
          return STATE_MACHINE_VALUES.INITIAL.PUBLISHED;
        }
        default: {
          return STATE_MACHINE_VALUES.INITIAL.UNPUBLISHED;
        }
      }
    }
  }

  // this check is needed for the product edit mode
  get isDraft() {
    if (this.isProductCollection) {
      return this.publishingStatus === PUBLISHING_STATUS.INACTIVE;
    }

    return this.announcementDate > DateTime.now();
  }

  // this check is needed for displaying the detail page with a lock icon
  get isInDraftState() {
    let isDraft = this.announcementDate?.ts > DateTime.now().ts;
    return isDraft || (this.isTixOnly && this.publishingStatus === PUBLISHING_STATUS.INACTIVE);
  }

  get isOnDispoList() {
    let dlpeModelname = this.abilities.can('dispoList.useThaliaOrderGrid')
      ? 'thalia-dispo-list-product-entry'
      : 'dispo-list-product-entry';
    let dispoListProducts = this.store
      .peekAll('dispo-list-product')
      .filter((record) => (!record.isDeleted || !record.hasDirtyAttributes) && !record.isNew);
    let dispoListProductEntries = this.store
      .peekAll(dlpeModelname)
      .filter((record) => (!record.isDeleted || !record.hasDirtyAttributes) && !record.isNew);

    return isProductOnDispoList({ dispoListProducts, dispoListProductEntries, productId: this.id });
  }

  get isOrderable() {
    return (
      !PRODUCT_FORMS_NOT_ORDERABLE.includes(this.form.typeO3) &&
      !this.isThemenspecial &&
      !UNPRICED_ITEM_CODES_NOT_ORDERABLE.includes(this.unpricedItemCode)
    );
  }

  get isProductCollection() {
    return this.isPromotionalPackage || this.isThemenspecial;
  }

  get isPromotionalPackage() {
    return (
      this.form.typeO3 === PRODUCT_FORM_TYPES.PACKAGE &&
      this.productComposition === PRODUCT_COMPOSITION_TYPES.PROMOTIONAL_PACKAGE
    );
  }

  get isPublished() {
    return this.publishingStatus === PUBLISHING_STATUS.ACTIVE;
  }

  get isThemenspecial() {
    return (
      this.form.typeO3 === PRODUCT_FORM_TYPES.PACKAGE && this.productComposition === PRODUCT_COMPOSITION_TYPES.SPECIAL
    );
  }

  get isTixOnly() {
    return this.type === PRODUCT_TYPES.TIX_ONLY;
  }

  get keywords() {
    let filteredKeywords = this.classifications?.filter((cl) => cl.type === CLASSIFICATION_TYPES.KEYWORD) ?? [];
    return sortBy(filteredKeywords, 'sequence');
  }

  get languagesToDisplay() {
    let main = this.languages?.filter((lang) => lang.type === LANGUAGE_TYPES.MAIN) ?? [];
    let audio = this.languages?.filter((lang) => lang.type === LANGUAGE_TYPES.AUDIO) ?? [];
    let subtitle = this.languages?.filter((lang) => lang.type === LANGUAGE_TYPES.SUBTITLE) ?? [];

    return [...main, ...audio, ...subtitle];
  }

  get mainPublisher() {
    return this.publishers?.find((publisher) => publisher.type === PUBLISHER_TYPES.MAIN);
  }

  get mainTitle() {
    let mainTitleObject = this.titles?.find((title) => title.titleType === TITLE_TYPES.MAIN);

    // if productInfo is present, we use this data preferably
    let mainTitle = this.productInfo?.title ?? mainTitleObject?.title ?? '';
    let mainSubtitle = this.productInfo?.subtitle ?? mainTitleObject?.subtitle ?? '';

    return {
      title: sanitizeString(mainTitle),
      subtitle: sanitizeString(mainSubtitle),
    };
  }

  get mostSignificantIdentifier() {
    let proprietaryIdentifier = this.identifiers?.find((identifier) => identifier.type === IDENTIFIER_TYPES.ORDER);
    return this.gtin ?? this.isbn ?? proprietaryIdentifier?.value;
  }

  get numberOfPieces() {
    return this.formFeatures?.find((feature) => feature.type === FORM_FEATURE_TYPES.PIECES)?.code;
  }

  get originator() {
    let sortedContributors = sortBy(this.contributors ?? [], 'sequenceNumber');
    return sortedContributors[0]?.name;
  }

  get originatorTitle() {
    return `${this.originator ? `${this.originator}: ` : ''}${this.mainTitle?.title}`;
  }

  get productInfo() {
    return this.info?.productInfo;
  }

  get readingRationalesVlb() {
    let readingRationales =
      this.classifications?.filter((cl) => cl.type === CLASSIFICATION_TYPES.READING_RATIONALE) ?? [];
    return readingRationales;
  }

  get referenceProducts() {
    return (
      this.relatedProducts?.filter((relatedProduct) =>
        REFERENCE_PRODUCT_REL_CODES.includes(relatedProduct.productRelationCode)
      ) || []
    );
  }

  get sortedReviews() {
    let reviews = this.textContents?.filter((text) => text.textType === TEXT_CONTENT_TYPE_CODES.REVIEW);
    return sortBy(reviews, 'sequence');
  }

  get salesNotes() {
    return this.textContents?.filter(
      (text) =>
        text.textType === TEXT_CONTENT_TYPE_CODES.SALES_NOTES &&
        text.textContentAudience.includes(TEXT_CONTENT_AUDIENCES.PUBLISHER)
    );
  }

  get sortedCollections() {
    return sortBy(this.collections, 'partNumber');
  }

  get tariffNumber() {
    let numbers = this.commercialClassifications?.filter((cl) => cl.type === '04') ?? [];
    return numbers[0]?.value;
  }

  get thema() {
    let mains = this.classifications?.filter((cl) => cl.type === CLASSIFICATION_TYPES.THEMA_SUBJECT && cl.main) ?? [];
    return sortBy(mains, 'sequence');
  }

  get themaSubjects() {
    let subjects =
      this.classifications?.filter((cl) => cl.type === CLASSIFICATION_TYPES.THEMA_SUBJECT && !cl.main) ?? [];
    return sortBy(subjects, 'sequence');
  }

  get themaQualifier() {
    let qualifier =
      this.classifications?.filter((cl) =>
        [
          CLASSIFICATION_TYPES.THEMA_GEO,
          CLASSIFICATION_TYPES.THEMA_LANGUGAGE,
          CLASSIFICATION_TYPES.THEMA_TIME,
          CLASSIFICATION_TYPES.THEMA_PEDAGOGICAL,
          CLASSIFICATION_TYPES.THEMA_AGE,
          CLASSIFICATION_TYPES.THEMA_STIL,
        ].includes(cl.type)
      ) ?? [];
    return sortBy(qualifier, 'sequence');
  }

  get warnings() {
    return this.formFeatures?.filter((feature) => feature.type === FORM_FEATURE_TYPES.WARNING) ?? [];
  }

  // sections
  get draftSections() {
    return this.sections?.filter((section) => section.status === SECTION_STATUS.DRAFT);
  }

  get publishedSections() {
    return this.sections?.filter((section) => section.status === SECTION_STATUS.PUBLISHED);
  }

  get sortedSections() {
    return sortBy(this.sections ?? [], 'position');
  }

  getAggregatedPrice(currencyCountry) {
    let currency = currencyCountry.split('_')[0];
    let country = currencyCountry.split('_')[1];

    return {
      value: this.prices[currencyCountry]?.retail?.value,
      discount: this.prices[currencyCountry]?.discount?.value,
      provisional: this.prices[currencyCountry]?.retail?.provisional,
      currency,
      country,
    };
  }
}
