import { isPresent } from '@ember/utils';
import { PARTY_PARTY_PERMISSIONS, PARTY_PERMISSIONS, PREMIUM_STATUS, USER_ROLES } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Ability from './-base';

export default class PartyAbility extends Ability {
  @service features;
  @service user;

  get addAssignedParty() {
    return this.viewUsers;
  }

  get addAssignedPartyToUser() {
    return this.viewAssignedPartyOfUser;
  }

  get addCustomerContact() {
    let party = this.user.selectedParty?.party;
    return this.view && party?.isPremium && this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_CUSTOMER_CONTACTS);
  }

  get addCustomerContactForAssignedParty() {
    return (
      this.viewCustomerContacts &&
      this.assignedParty?.isPremium &&
      this.assignedParty?.permissionSet?.permissions?.includes(PARTY_PERMISSIONS.MANAGE_CUSTOMER_CONTACTS)
    );
  }

  get deleteAssignedPartyOfUser() {
    return this.viewAssignedPartyOfUser;
  }

  get deleteCustomerContact() {
    return this.editCustomerContact;
  }

  get deleteUser() {
    // a BO user is allowed to delete any user regardless of the assigned parties and permissions
    if (this.user.current.roles.includes(USER_ROLES.TIX_BO)) {
      return true;
    }

    // a user is allowed to delete another user
    // if the user has the permission to manage users for every party of the parties the user to delete is assigned to
    let assignedPartyIdsOfUserToDelete = this.assignedPartiesOfUserToDelete?.map((party) => party.party?.id);

    // in the tab users on the my party page we only get to see the users we can actually manage
    // some users might have more parties assigned than we can see (but do not concern us on this page)
    // with this check we make sure that we can only delete users we can actually manage
    if (this.assignedCountOfUserToDelete > assignedPartyIdsOfUserToDelete?.length) {
      return false;
    }

    return assignedPartyIdsOfUserToDelete?.every((partyId) => {
      let assignedParty = this._findAssignedParty(partyId);
      let hasPermission = assignedParty?.hasPermission(PARTY_PERMISSIONS.MANAGE_USERS) ?? false;

      return isPresent(assignedParty) && hasPermission;
    });
  }

  get editAssignedPartyOfUser() {
    return this.viewAssignedPartyOfUser;
  }

  get editCompanyProfilePage() {
    let hasEditPartyProfilePermission = this.user.assignedPartiesPermissionsMap.some((assignedParty) => {
      let userHasPartyAssigned = assignedParty.mvbId === this.model.mvbId;
      let userHasEditPartyProfilePermission = assignedParty.permissions.includes(PARTY_PERMISSIONS.EDIT_PARTY_PROFILE);
      return userHasPartyAssigned && userHasEditPartyProfilePermission;
    });

    return this.linkToCompanyProfilePage && hasEditPartyProfilePermission;
  }

  get editCustomerContact() {
    let party = this.customerContact?.party;
    let assignedParty = this._findAssignedParty(party?.id);

    return (
      this.viewCustomerContacts &&
      party?.isPremium &&
      assignedParty?.permissionSet?.permissions?.includes(PARTY_PERMISSIONS.MANAGE_CUSTOMER_CONTACTS)
    );
  }

  get editPermissionSet() {
    let party = this.user.selectedParty?.party;
    return this.view && party?.isPremium && this.user.hasPermission(PARTY_PERMISSIONS.EDIT_PERMISSIONS);
  }

  get linkToCompanyProfilePage() {
    return this.features.isEnabled('party-profile-page') && this.model?.premiumStatus === PREMIUM_STATUS.PREMIUM;
  }

  get manageGroups() {
    return this.viewGroups && this.user.hasPermission(PARTY_PERMISSIONS.EDIT_GROUPS);
  }

  get view() {
    return !!this.user.selectedParty;
  }

  get viewAssignedPartyOfUser() {
    let assignedParty = this._findAssignedParty(this.assignedParty?.party?.id);
    return this.viewUsers && assignedParty?.permissionSet?.permissions?.includes(PARTY_PERMISSIONS.MANAGE_USERS);
  }

  get viewBookstoreBranches() {
    return (
      this.view &&
      this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_BRANCHES) &&
      !this.user.hasAnyPermission([
        PARTY_PERMISSIONS.EDIT_THALIA_ORDER_GRID_QUANTITIES,
        PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT,
        PARTY_PERMISSIONS.SHOW_THALIA_ORDER_GRID,
      ])
    );
  }

  get viewCategoryOfGoods() {
    return this.view && this.user.hasPermission(PARTY_PERMISSIONS.UPLOAD_CATEGORY_OF_GOODS);
  }

  get viewCentralList() {
    return this.view && this.user.hasPermission(PARTY_PERMISSIONS.THALIA_IMPORT_ORDER_RECOMMENDATIONS);
  }

  get viewCollectionOfGoods() {
    return (
      this.view &&
      this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_COLLECTION_OF_GOODS) &&
      !this.user.hasAnyPermission([
        PARTY_PERMISSIONS.EDIT_THALIA_ORDER_GRID_QUANTITIES,
        PARTY_PERMISSIONS.FOXXY_ASSORTMENT_IMPORT,
        PARTY_PERMISSIONS.SHOW_THALIA_ORDER_GRID,
      ])
    );
  }

  get viewCompanies() {
    let selectedParty = this.user.selectedParty;
    let selectedPartyIsCompany =
      selectedParty?.party?.isBookGroup || selectedParty?.party?.isPublisher || selectedParty?.party?.isSalesCoop;

    return (
      this.features.isEnabled('party-profile-page') && this.view && selectedParty?.isPremium && selectedPartyIsCompany
    );
  }

  get viewCompanyPublishedPage() {
    return this.editCompanyProfilePage;
  }

  get viewCustomerContacts() {
    return (
      this.view &&
      this.user.selectedParty?.isPremium &&
      this.user.assignedParties?.some(
        (assignedParty) =>
          assignedParty.party?.isBookGroup || assignedParty.party?.isPublisher || assignedParty.party?.isSalesCoop
      )
    );
  }

  get viewErp() {
    return (
      this.view &&
      this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_ERP) &&
      !this.user.hasAnyPermission([
        PARTY_PERMISSIONS.EDIT_THALIA_ORDER_GRID_QUANTITIES,
        PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT,
        PARTY_PERMISSIONS.SHOW_THALIA_ORDER_GRID,
      ])
    );
  }

  get viewErpFoxxyCallout() {
    return this.view && this.user.hasPermission(PARTY_PERMISSIONS.FOXXY_DISPOLIST_EXPORT);
  }

  get viewGroups() {
    return this.features.isEnabled('groups') && this.view;
  }

  get viewJWWSLinks() {
    let party = this.user.selectedParty?.party;
    return party?.partyPermissions.includes(PARTY_PARTY_PERMISSIONS.SHOW_JWWS_LINKS);
  }

  get viewLinkedParties() {
    return this.features.isEnabled('linkedParties') && this.view && (this.user.current?.hasAssignedParties ?? false);
  }

  get viewMyProducts() {
    return this.user.assignedParties?.some(
      (assignedParty) =>
        assignedParty.party?.isBookGroup || assignedParty.party?.isPublisher || assignedParty.party?.isSalesCoop
    );
  }

  get viewPermissions() {
    return this.view;
  }

  get viewSettings() {
    return this.viewSettingsAdvertisingAddress || this.viewSettingsFtpAccess;
  }

  get viewSettingsAdvertisingAddress() {
    return (
      this.view && this.user.selectedParty?.isPremium && this.user.hasPermission(PARTY_PERMISSIONS.SET_ADVERT_ADDRESS)
    );
  }

  get viewSettingsFtpAccess() {
    let party = this.user.selectedParty?.party;

    return (
      this.view &&
      party?.isPremium &&
      (party.partyPermissions.includes(PARTY_PARTY_PERMISSIONS.PREVIEW_IMPORT) ||
        this.user.hasAnyPermission([PARTY_PERMISSIONS.CREATE_ACTIONS_SPECIALS, PARTY_PERMISSIONS.EDIT_TITLE_DETAILS]))
    );
  }

  get viewWebshopLinkSettings() {
    let party = this.user.selectedParty?.party;

    return (
      this.view &&
      this.features.isEnabled('shopLinkSettings') &&
      party?.isPremium &&
      party?.partyPermissions.includes(PARTY_PARTY_PERMISSIONS.MANAGE_BOOKSTORE_PREVIEWS) &&
      this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_BOOKSTORE_PREVIEWS)
    );
  }

  get viewSpecialsOnly() {
    return Boolean(this.user.current?.isBlogger || this.user.current?.isReader || this.user.current?.isJournalist);
  }

  get viewTitleUpload() {
    return (
      this.view &&
      this.user.selectedParty?.isPremium &&
      this.user.hasPermission(PARTY_PERMISSIONS.BOOKGROUP_TITLE_UPLOAD)
    );
  }

  get viewUsers() {
    return (
      this.features.isEnabled('showManageUserTab') &&
      this.view &&
      this.user.hasPermission(PARTY_PERMISSIONS.MANAGE_USERS)
    );
  }

  get viewWkz() {
    return this.view && this.user.hasPermission(PARTY_PERMISSIONS.MARKETINGTREE_IMPORT);
  }

  _findAssignedParty(partyId) {
    return this.user.assignedParties?.find((assignedParty) => assignedParty.party?.id === partyId);
  }
}
