import { compare, isPresent } from '@ember/utils';
import { RETAIL_SALE_TYPES } from '../constants';
import { SEARCH_MODE } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import SearchBaseService from '@mvb/tix-ui/services/search-base';

export default class SearchSpecialsService extends SearchBaseService {
  @service features;
  @service intl;
  @service store;
  @service user;

  get queryParamsDefaults() {
    return {
      containedItems: null,
      page: 1,
      publisherId: '',
      q: '',
      sort: '',
    };
  }

  get queryParamsFilters() {
    return ['containedItems', 'publisherId'];
  }

  get queryParamsUserSpecific() {
    return {
      backlist: this.user.canAccessBacklist && this.user.useBacklist,
      size: this.user.selectedUserPagesize,
    };
  }

  get sortOrders() {
    let sortOrders = [];

    if (this.features.isEnabled('specialsSearchSorting')) {
      sortOrders = [
        {
          text: this.intl.t('productsIndex.text.sorting.relevance'),
          value: '',
        },
        {
          text: this.intl.t('productsIndex.text.sorting.explicitPublicationDateUp'),
          value: 'explicitPublicationDate',
        },
        {
          text: this.intl.t('productsIndex.text.sorting.explicitPublicationDateDown'),
          value: '-explicitPublicationDate',
        },
        {
          text: this.intl.t('productsIndex.text.sorting.publisherSortUp'),
          value: 'publisherSort',
        },
        {
          text: this.intl.t('productsIndex.text.sorting.publisherSortDown'),
          value: '-publisherSort',
        },
      ];
    }

    return sortOrders;
  }

  createSearchQuery({ queryParams }) {
    let { backlist, page, q, size, sort } = queryParams;
    let query = {
      filter: {
        backlist,
        searchMode: SEARCH_MODE.ADVANCED,
        retailSale: RETAIL_SALE_TYPES.SPECIAL,
      },
      include: 'notes',
      page: {
        number: page,
        size,
      },
    };

    this.addFiltersToSearchQuery({ query, queryParams });

    if (isPresent(sort)) {
      query.sort = sort;
    }

    if (isPresent(q)) {
      query.filter.searchTerm = q;
    }

    return query;
  }

  async executeSearchQuery(query) {
    if (compare(query?.filter?.publisherId?.split(','), this.user.favoritePublishers) === 0) {
      query.filter.favoritePublishers = true;
      delete query.filter.publisherId;
    }
    return await this.store.query('search-product', query);
  }

  overrideQueryParameters(queryParamsOverride) {
    if (!this.user.canAccessBacklist) {
      queryParamsOverride.backlist = false;
    }

    return queryParamsOverride;
  }
}
