import { action } from '@ember/object';
import { all, task } from 'ember-concurrency';
import { ICON_TYPES } from '@mvb/tix-ui/components/ui/function-bar';
import { MAX_PRODUCTS_FOR_BATCH_ADD } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';

export default class OrderOrderListIndexController extends Controller {
  @service branches;
  @service dispoLists;
  @service errors;
  @service eventBus;
  @service intl;
  @service manageList;
  @service notifications;
  @service progress;
  @service router;
  @service('search-products-in-order-list') searchService;
  @service userState;

  queryParams = ['qp'];
  @tracked currentProgress;
  @tracked isLoading = false;
  @tracked qp = '';

  ICON_TYPES = ICON_TYPES;

  constructor() {
    super(...arguments);
    this.eventBus.on('setOrderListIsLoading', this, this.setIsLoading);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.eventBus.off('setOrderListIsLoading', this, this.setIsLoading);
  }

  get addToDispoListLabel() {
    return this.maxProductCountExceeded
      ? this.intl.t('uiFunctionBar.tooltip.dispoListMaxProductCountExceeded')
      : this.intl.t('uiFunctionBar.tooltip.moveToDispolist');
  }

  get orderListIsEmpty() {
    return !this.model?.openOrderList?.productCount;
  }

  get isItemsSelectionEmpty() {
    return this.manageList.isSelectionEmpty || this.searchService.meta.total === 0;
  }

  get maxProductCountExceeded() {
    let selectedProductCount = this.manageList.determineSelectedElementsCount(this.searchService.meta.total);
    return selectedProductCount > MAX_PRODUCTS_FOR_BATCH_ADD;
  }

  get searchTask() {
    let { isRunning: loadTaskIsRunning, lastSuccessful, performCount } = this.searchService.searchTask ?? {};
    let { isRunning: deleteTaskIsRunning } = this.deleteRecordAndReloadListTask ?? {};

    return {
      isIdle: !(deleteTaskIsRunning || loadTaskIsRunning),
      isRunning: deleteTaskIsRunning || loadTaskIsRunning,
      lastSuccessful: { value: lastSuccessful?.value ?? [] },
      performCount,
    };
  }

  @task
  @waitFor
  *destroyRecordAndReloadListTask(record, index) {
    try {
      yield record.destroyRecord();
      yield this.reloadListTask.perform({ fullReload: true }, index);
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *reloadListTask({ fullReload = false } = {}, index) {
    try {
      yield all([this.model.openOrderList?.reload(), this.userState.load()]);

      if (fullReload) {
        return index ? this.searchService.search({ index }) : this.router.refreshCurrentRoute({ withoutIndex: true });
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task
  @waitFor
  *saveEntriesTask() {
    try {
      yield this.reloadListTask.perform();
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @action
  buildFilter(filterName, filterValue) {
    let filter = {};
    filter[filterName] = filterValue;
    return filter;
  }

  @action
  finalizeOrderList() {
    this.router.transitionTo('order.order-list.finalize');
  }

  @action
  async onAddToDispoList() {
    if (this.isItemsSelectionEmpty) {
      return this.notifications.info(this.intl.t('modalAddToDispoList.notification.noItemsSelected'));
    }

    let affectsResults = false;

    try {
      affectsResults = await this.dispoLists.addToDispoList({
        description:
          this.branches.current?.length > 0
            ? this.intl.t('modalAddToDispoList.text.descriptionPutBackFromOrderList')
            : undefined,
        selectedItems: Array.from(this.manageList?.selectedItems),
        invertedSelection: this.manageList.inverse,
        orderListId: this.model?.openOrderList?.id,
        successMessage: this.intl.t('modalAddToDispoList.notification.successPutBackFromOrderList'),
        title: this.intl.t('modalAddToDispoList.text.titlePutBackFromOrderList'),
      });
    } catch (error) {
      this.errors.handle(error);
    }

    if (affectsResults) {
      this.reloadListTask.perform({ fullReload: true });
    }
  }

  @action
  setIsLoading(value) {
    this.isLoading = value;
    this.handleProgress();
  }

  handleProgress() {
    if (this.isLoading) {
      this.currentProgress = this.progress.add({
        message: this.intl.t('loading.progress'),
      });
    }

    if (!this.isLoading && this.currentProgress) {
      this.currentProgress.remove();
    }
  }
}
