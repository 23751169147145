import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { PARTY_PARTY_PERMISSIONS, PARTY_TYPE, PREMIUM_STATUS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Controller from '@ember/controller';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import ModalNewPermissionSet from '@mvb/tix-ui/components/backoffice/new-permission-set-modal';
import ModalPartyPermissionDelete from '@mvb/tix-ui/components/party/modal-permission-delete';
import PermissionSetValidations from '@mvb/tix-ui/validations/permission-set';

const ICON_PREMIUM = 'crown';
const ALLOWED_PARTY_TYPES = [PARTY_TYPE.PUBLISHER, PARTY_TYPE.SALES_COOP, PARTY_TYPE.BOOK_GROUP];

export default class PartyPermissionsController extends Controller {
  @service api;
  @service errors;
  @service intl;
  @service modals;
  @service notifications;
  @service router;
  @service store;
  @service user;

  @tracked newPermissionSet = null;
  @tracked editingPermissionSet = null;
  @tracked changeset = null;

  get hasNoAdditionalRightSearchPlus() {
    let partyPermissions = this.user.selectedParty.party.partyPermissions;
    return !partyPermissions.includes(PARTY_PARTY_PERMISSIONS.EXTENDED_SEARCH);
  }

  get numberOfColumns() {
    return this.model.permissionSets.length + 1;
  }

  get rowsByCategory() {
    return [PREMIUM_STATUS.BASIC, PREMIUM_STATUS.PREMIUM]
      .map((premiumStatus) => ({
        categories: this.model.permissions
          .map(({ category, values }) => ({
            category,
            rows: values
              .filter(
                ({ premium }) =>
                  (premiumStatus === PREMIUM_STATUS.BASIC && !premium) ||
                  (premiumStatus === PREMIUM_STATUS.PREMIUM && premium)
              )
              .map(({ name, value: permission }) => ({
                name,
                permission,
                values: this.sortedPermissionSets.map((permissionSet) => ({
                  hasPermission: permissionSet.permissions?.includes(permission),
                  permissionSet,
                })),
              }))
              .sort((a, b) => a.name.localeCompare(b.name)),
          }))
          .filter((category) => category.rows.length > 0),
        icon: premiumStatus === PREMIUM_STATUS.PREMIUM ? ICON_PREMIUM : null,
        premiumStatus,
        title: this.intl.t(`partyPermissions.text.permissions.${premiumStatus}`),
        tooltip: this.intl.t(`partyPermissions.tooltip.permissions.${premiumStatus}`),
      }))
      .filter((premiumCategory) => premiumCategory.categories.length > 0);
  }

  get showSearchPlusTooltip() {
    let selectedPartyType = this.user.selectedParty.party.type;
    return ALLOWED_PARTY_TYPES.includes(selectedPartyType);
  }

  get sortedPermissionSets() {
    let permissionSets = this.model.permissionSets.filter(
      (permissionSet) => !permissionSet.isDeleted || permissionSet.isSaving || permissionSet.hasDirtyAttributes
    );

    permissionSets = permissionSets.sort((a, b) => {
      if (a.predefined && !b.predefined) {
        return -1;
      } else if (b.predefined && !a.predefined) {
        return 1;
      }
      return a.label.localeCompare(b.label);
    });

    if (this.newPermissionSet) {
      return [...permissionSets, this.newPermissionSet];
    }

    return permissionSets;
  }

  @task({ drop: true })
  *createTask() {
    try {
      let result = yield this.modals.open(ModalNewPermissionSet, {
        permissionSets: this.sortedPermissionSets,
      });

      if (!result) {
        return;
      }

      let permissionSet = this.store.createRecord('permission-set');

      permissionSet.permissions =
        result === 'none' ? [...this.model.minimalLockedPermissions] : [...result.permissions];

      this.newPermissionSet = permissionSet;
      this.editPermissionSet(permissionSet);
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ drop: true })
  *deleteTask() {
    try {
      let targetPermissionSetId = yield this.modals.open(ModalPartyPermissionDelete, {
        currentPermissionSet: this.editingPermissionSet,
        permissionSets: this.sortedPermissionSets,
      });

      if (targetPermissionSetId) {
        yield this.api.postJSON('/permission-sets/swap', {
          removeSourcePermissionSet: true,
          sourcePermissionSetToShiftFrom: this.editingPermissionSet.id,
          targetPermissionSetToShiftTo: targetPermissionSetId,
        });
        yield this.editingPermissionSet.deleteRecord();
        this.notifications.success(this.intl.t('partyPermissions.notification.deleteSuccess'));
        this.stopEditing();
      }
    } catch (error) {
      this.editingPermissionSet.rollbackAttributes();

      this.errors.handle(error);
    }
  }

  @task({ drop: true })
  @waitFor
  *saveTask() {
    try {
      let isNew = this.changeset.data.isNew;

      yield this.changeset.validate();

      if (this.changeset.isValid) {
        yield this.changeset.save();

        if (isNew) {
          yield this.router.refresh();
        }

        this.stopEditing();
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @action
  cancel() {
    if (this.editingPermissionSet.isNew) {
      this.editingPermissionSet.unloadRecord();
    } else {
      this.editingPermissionSet.rollbackAttributes();
    }
    this.stopEditing();
  }

  @action
  create() {
    return this.createTask.perform();
  }

  @action
  delete(ps) {
    return this.deleteTask.perform(ps);
  }

  // this is only needed to prevent Firefox from miscalculating the width of the permissionSet name
  // can be removed when https://bugzilla.mozilla.org/show_bug.cgi?id=1310551 is fixed
  @action
  didInsertPermissionSetName(element) {
    next(this, () => {
      if (element) {
        element.style.width = 'auto';
      }
    });
  }

  @action
  editPermissionSet(permissionSet) {
    this.changeset = createChangeset(
      permissionSet,
      PermissionSetValidations({ permissionSets: this.sortedPermissionSets })
    );
    this.editingPermissionSet = permissionSet;
  }

  @action
  noop() {
    return;
  }

  @action
  save(ps) {
    return this.saveTask.perform(ps);
  }

  @action
  stopEditing() {
    this.changeset = null;
    this.editingPermissionSet = null;
    this.newPermissionSet = null;
  }

  @action
  togglePermission(permissionSet, permission) {
    let permissions = [...permissionSet.permissions];

    if (permissionSet.permissions.includes(permission)) {
      permissions.splice(permissionSet.permissions.indexOf(permission), 1);
    } else {
      permissions.push(permission);
    }

    permissionSet.permissions = permissions;
  }
}
