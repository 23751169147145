import { action } from '@ember/object';
import { PUBLISH_ACTION } from '@mvb/tix-ui/constants';
import SectionContentsProductCollectionBaseService from '@mvb/tix-ui/services/section-contents-product-collection-base';

export default class SectionContentsSpecialService extends SectionContentsProductCollectionBaseService {
  intlNamespace = 'specialsProductEdit';
  presentationPreview = null;

  setupController(model, presentationPreview) {
    this.model = model;
    this.presentationPreview = presentationPreview;
  }

  async save() {
    await this.saveTask.perform();

    if (this.router.get('currentRouteName') === 'specials.add') {
      this.router.transitionTo('specials.product.edit', this.model.id);
    }

    // we always want to reload the route, even if we had a transition first
    this.reloadCurrentRoute();
  }

  async saveAndClose() {
    await this.saveTask.perform();
    await this.transitionToViewPage(true);
  }

  @action
  async publish() {
    await this.saveTask.perform({ adapterOptions: { publishAction: PUBLISH_ACTION.PUBLISH } });
    await this.transitionToViewPage(true);
  }

  @action
  async unpublish() {
    await this.saveTask.perform({ adapterOptions: { publishAction: PUBLISH_ACTION.DRAFT } });

    this.reloadCurrentRoute();
  }

  @action
  async cancel() {
    await this.transitionToViewPage(false);
  }

  async transitionToViewPage(shouldReload = false) {
    if (this.presentationPreview?.id) {
      this.router.transitionTo('presentations.preview.product', this.presentationPreview.id, this.model.id);
    } else if (this.model.active) {
      if (shouldReload) {
        await this.reloadModel();
      }
      this.router.transitionTo('specials.product.index', this.model.id);
    } else {
      this.router.transitionTo('specials.index');
    }
  }
}
