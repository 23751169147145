import { SECTION_STATUS } from '@mvb/tix-ui/constants';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

const ROUTE_PREVIEW_PRODUCT = 'presentations.preview.product';
const ROUTE_SPECIALS = 'specials';
const ROUTE_SPECIALS_PRODUCT = 'specials.product.index';
const ROUTE_PRODUCT_DETAIL = 'products.product.index';

export default class ProductsProductEditRoute extends Route {
  @service abilities;
  @service router;
  @service('section-contents-product') sectionContentsProduct;
  @service('section-contents-promotional-package') sectionContentsPromotionalPackage;
  @service('section-contents-special') sectionContentsSpecial;
  @service session;
  @service store;

  beforeModel(transition) {
    if (transition.targetName.includes('specials')) {
      this.session.requireAuthentication(transition, ROUTE_SPECIALS_PRODUCT);
    } else {
      this.session.requireAuthentication(transition, ROUTE_PRODUCT_DETAIL);
    }
  }

  model() {
    let { product_id: productId } = this.paramsFor('products.product');

    if (this.routeName.startsWith('specials')) {
      productId = this.paramsFor('specials.product').product_id;
    }

    return this.store.findRecord('product', productId, {
      adapterOptions: {
        query: {
          filter: {
            section: {
              status: SECTION_STATUS.DRAFT,
            },
          },
        },
      },
      include: [
        'contributors',
        'supportingResources',
        'textContents',
        'sections',
        'sections.contents',
        'sections.contents.file',
        'sections.contents.prices',
        'sections.deliveryInfo',
        'sections.productInfo',
        'notes.creator',
      ].join(','),
    });
  }

  afterModel(model) {
    if (this.abilities.cannot('product.edit', model)) {
      if (this.routeName.startsWith('specials')) {
        return this.router.replaceWith('specials.product.index');
      }

      return this.router.replaceWith('products.product.index');
    }
  }

  setupController(_, model, transition) {
    super.setupController(...arguments);

    let presentationPreview;
    if (transition.from?.name?.includes(ROUTE_PREVIEW_PRODUCT)) {
      presentationPreview = transition.from.attributes?.preview;
    }

    if (model.isPromotionalPackage) {
      this.setupPromotionalPackages(model, presentationPreview);
    } else if (model.isThemenspecial) {
      this.setupSpecials(model, presentationPreview);
    } else {
      this.setupProducts(model, transition, presentationPreview);
    }
  }

  setupProducts(model, transition, presentationPreview) {
    if (
      transition.from?.name === ROUTE_SPECIALS_PRODUCT ||
      (!transition.from && transition.to?.name?.startsWith(ROUTE_SPECIALS))
    ) {
      this.setupSpecials(model, presentationPreview);
    }

    this.sectionContentsProduct.setupController(model, presentationPreview);
  }

  setupPromotionalPackages(model, presentationPreview) {
    this.sectionContentsPromotionalPackage.setupController(model, presentationPreview);
  }

  setupSpecials(model, presentationPreview) {
    this.sectionContentsSpecial.setupController(model, presentationPreview);
  }

  resetController() {
    this.sectionContentsProduct.resetController(...arguments);
    this.sectionContentsPromotionalPackage.resetController(...arguments);
    this.sectionContentsSpecial.resetController(...arguments);
  }

  activate() {
    super.activate();

    this.sectionContentsProduct.activate(...arguments);
    this.sectionContentsPromotionalPackage.activate(...arguments);
    this.sectionContentsSpecial.activate(...arguments);
  }

  deactivate() {
    super.deactivate();

    this.sectionContentsProduct.deactivate(...arguments);
    this.sectionContentsPromotionalPackage.deactivate(...arguments);
    this.sectionContentsSpecial.deactivate(...arguments);
  }
}
